import { getRequest, postRequest,urlTitle } from "@/utils/request";
import qs from "qs";
window.qs = qs;
// 巡检单管理
export const inspectionFormApi = {
  // 巡检项列表
  queryInspectionItem: (params) => {
    return getRequest(`${urlTitle}/operation/inspection/list`,params);
  },
    // 
    queryStationName: () => {
      return getRequest(`${urlTitle}/power/plan/stationName`,);
    },
      // 判断巡检单是否可用
      useInspectionForm: (id) => {
    return getRequest(`${urlTitle}/operation/inspection/form/get/operation/inspection/${id}`);
  },
  // 巡检单列表
  queryInspectionForm: (params) => {
    return getRequest(`${urlTitle}/operation/inspection/form/page/list`, params);
  },
    // 巡检单列表
    queryInspectionDetails: (id) => {
      return getRequest(`${urlTitle}/operation/inspection/form/get/${id}`,);
    },

  // 创建巡检单
  createInspectionForm: (params) => {
    return postRequest(`${urlTitle}/operation/inspection/form/add`, params);
  },
  // 修改巡检单
  updateInspectionForm: (params) => {
    return postRequest(`${urlTitle}/operation/inspection/form/update`, params);
  },
  // 删除巡检单
  deleteInspectionForm: (id) => {
    return postRequest(`${urlTitle}/operation/inspection/form/${id}`);
  },
};
